
@font-face {
	font-family: 'quran';
	src: url('/font/me_quran.ttf');
	src: local('quran'), url('/font/me_quran.ttf') format('truetype');
}

*{
  padding: 0;
  margin: 0;
  word-spacing: 4px;
}

h1{
  font-size: 25px;
  /* background: radial-gradient(circle, rgba(255, 194, 220, 1) 10%, rgba(148,187,233,0.6 ) 100%); */
  width: 200px;
  
  position: fixed;
  background: linear-gradient(180deg,
  rgba(155, 202, 199,1) -110%,
  rgb(251, 249, 255) 100%);
 
  bottom: -1px;
  right: -30px;
  border-radius: 50px 00px 0px 0px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  box-shadow: 1px 2px 13px  rgba(158, 158, 158, 0.555)  ;
  

 
}

 .reglage{
   position: relative;
   top:37px;
   right: 10px;
 }

h2 {font-family:quran}

.config{

   background-color: white;
   position: fixed;
   height:84px;
   width: 84px;
   border-radius: 64px;
   
  
  text-align: center;
  right: -30px;
  top: -30px;
  box-shadow: 1px 2px 10px rgb(192, 192, 192);
  z-index: 2;

}

h2 {
  font-weight: lighter;
  text-align: center;
  font-size: 36px;
  padding-top: 100px;
  height: 200px;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
 
}


.ayah_active {
  background: linear-gradient(180deg,
   rgba(127, 233, 226, 0.1335) 0%,
   rgba(112, 0, 255, 0.101516) 95.83%,
   rgba(255, 255, 255, 0) 100%);
  border-radius: 10px;
  height: 350px;
  box-sizing: content-box;

}

.coeur{
  position: absolute;
  top: 235px;
  left: 10px;
}

.partage{
  position: absolute;
  top:235px ;
  right: 15px;
}


.apercu_ayat{
  padding-top: 50px;
  text-align: right;
  direction: rtl;
   
  margin-top: -35px;
 
  background: #F7F9F8;
  box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 50px 50px 0px 0px;
  min-height: 580px;
  height: 600px;
  position:fixed;
  overflow-y: scroll;

 

 
  
}

.apercu_ayat> p {
  
  text-align: right;
  font-size: 25px;
  line-height: 50px;

  padding: 45px;
  width: 96%;
  margin-left: 2%;
  margin-right: 2%;
  box-sizing: border-box;
 
  margin-bottom: 17px;
  box-shadow: 3px 3px 10px 0px rgb(184 183 183);
 
  position: relative;
  border-radius: 25px;

  
}

.rec1 {
  width: 400px;
  height: 200px;
  background: rgba(112, 0, 255, 0.101516);
}



body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: quran;
  -webkit-font-smoothing: quran;
  -moz-osx-font-smoothing: quran;
}



code {
  font-family: quran
}

.bouton{ 
  position: relative;
  left: 10px;
  top: 18px;
 }

.audio{
  position: fixed;
  bottom: -30px;
  left: -30px;


  height:84px;
  width: 84px;
  border-radius: 64px;
 
 text-align: center;
 
 box-shadow: 1px 2px 13px rgb(175, 175, 175);
  /* background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,0.5) 100%);   */
  /* /background-color: white; */
  background: linear-gradient(180deg,
  rgba(127, 233, 226, 0.1335) 0%,
  rgba(112, 0, 255, 0.101516) 95.83%,
  rgba(255, 255, 255, 0) 100%);
}

.num_verset {
  margin-right: 5px;
  display: inline-block;
  color: rgb(148, 177, 0);
  background-image: url(./img/accolade_gauche.png), url(./img/accolade_droite.png);
  background-position: center left, center right;
  background-size: 8px;
  background-repeat: no-repeat;
  font-size: 13px;
  padding: 0px 12px 0px 12px;
  font-weight: bold;
  position: absolute;
  right: 10px;
  top: 0px;}

  .modal{
    position: fixed;
     background: white;
    height: 100%;
    width: 60%;
    z-index: 1;
    right: 0;
    top: 00px;
    box-shadow: 2px 3px 13px grey;
    padding: 30px;
  }


  .rc-slider-handle {
    position: absolute;
    width: 40px !important;
    height: 40px!important;
    cursor: pointer;
    cursor: -webkit-grab;
    margin-top: -16px !important;
    cursor: grab;
    border-radius: 50%;
    border: solid 2px #96dbfa;
    background :linear-gradient(180deg,
    rgba(127, 233, 226, 0.1335) 0%,
    rgba(112, 0, 255, 0.101516) 95.83%,
    rgba(255, 255, 255, 0) 100%);
    touch-action: pan-x;
}

.configuration{
  position: relative;
  top:100px; 
  font-size: 20px;

}

.configuration > * {
  display: block;
  margin-bottom: 15px;

}

.textconfig{
  text-align: center;
}



.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  font-family: 'Open Sans', 'Helvetica Neue', 'Segoe UI', 'Calibri', 'Arial', sans-serif;
  font-size: 18px;
  color: #60666d;
  margin-bottom: 10px;
}
/* input[type=range][orient=vertical]
{
    writing-mode: bt-lr;  
    -webkit-appearance: slider-vertical;  
    width: 18px;
    height: 175px;
    
    position: fixed;
    right: 10px;

} */

html {
  --scrollbarBG: rgba(214, 127, 127, 0);
  --thumbBG: rgba(144, 164, 174, 0);
}
.apercu_ayat::-webkit-scrollbar {
  width: 0px;
}
.apercu_ayat {
  scrollbar-width:25px;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.apercu_ayat::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.apercu_ayat::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 14px;
  border: 1px solid var(--scrollbarBG);
  z-index: 0;
}
 
.borded{
  border: 1px solid black; 
}
.MenuM{position:fixed;bottom:35%;width:50px;height:270px;left:-48px;z-index:6;transition:all 0.4s ease;}
.MenuM > ul{text-align:center;margin:0;background-color:rgba(255, 250, 250, 0);}
.MenuM > ul > li{
  width: 46px;
    height: 55px;
    border-radius: 12px;
    margin-top: 5px;
    line-height: 43px;


}
.animation{opacity:1;transform:perspective(10px) translate3d(60px,0px,0px);}
ul{list-style-type:none;margin:0;padding:0;z-index:9;background-color:#f1f1f1;margin-top:70px;}
.orange{background-color:rgba(251, 181, 54, 0.72);}
.rose{background-color:rgba(234, 59, 132, 0.22);}
.vert{background-color:rgba(130, 199, 102, 0.58);}
.bleu{background-color:rgba(102, 199, 191, 0.61);}
.darckblue{background-color:rgba(57, 103, 152, 0.45);}
.MenuM{position:fixed;bottom:35%;width:50px;height:270px;left:-48px;z-index:6;transition:all 0.4s ease;}
.MenuM > ul{text-align:center;margin:0;background-color:rgba(255, 250, 250, 0);}
.MenuM > ul > li{width: 46px;
  height: 55px;
  border-radius: 12px;
  margin-top: 5px;
  line-height: 43px;
  box-shadow: 2px 2px 2px 0px #7a77778c;


}
.animation{opacity:1;transform:perspective(10px) translate3d(60px,0px,0px);}
ul{list-style-type:none;margin:0;padding:0;z-index:9;background-color:#f1f1f1;margin-top:70px;}
.orange{background-color:rgba(251, 181, 54, 0.72);}
.rose{background-color:rgba(234, 59, 132, 0.22);}
.vert{background-color:rgba(130, 199, 102, 0.58);}
.bleu{background-color:rgba(102, 199, 191, 0.61);}
.darckblue{background-color:rgba(57, 103, 152, 0.45);}
ul{list-style-type:none;margin:0;padding:0;z-index:9;background-color:#f1f1f1;margin-top:70px;}
.orange{background-color:rgba(251, 181, 54, 0.72);}
.rose{background-color:rgba(234, 59, 132, 0.22);}
.vert{background-color:rgba(130, 199, 102, 0.58);}
.bleu{background-color:rgba(102, 199, 191, 0.61);}
.darckblue{background-color:rgba(57, 103, 152, 0.45);}
.back{background-color:rgb(185, 160, 255);}
.MenuM > ul > li> img { width: 25px; margin-top: 10px;}

.range{ position: absolute; font-size: 11px; left: 8px; top:8px } 